@import "../../styles/variables.module";
.rating {
  display: flex;
  margin-bottom: 0;
  @include extra_large {
    margin-bottom: 10px;
  }
  @include mobile_device {
    flex-wrap: wrap;
  }
  .left {
    background: $white-color;
    width: 50px;
    height: 50px;
    border-radius: 8px;
    text-align: center;
    line-height: 44px;
    border: 1px solid #C4C4C4;
    display: flex;
    justify-content: center;
    align-items: center;
    @include up_large {
      width: 40px;
      height: 40px;
      line-height: 35px;
    }
    @include mobile_device {
      width: 30px;
      height: 30px;
      line-height: 28px;
      margin: 0 auto;
    }
    img {
      width: 25px !important;
      height: 25px !important;
      @include up_large {
        width: 20px !important;
        height: 20px !important;
      }
      @include mobile_device {
        width: 18px !important;
        height: 18px !important;
      }
    }
  }
  .right {
    margin-left: 15px;
    width: calc(100% - 65px);
    @include up_large {
      margin-left: 10px;
    }
    @include mobile_device {
      width: 100%;
      margin-left: 0;
      text-align: center;
    }
    .name {
      @include mobile_device {
        margin-top: 10px;
        margin-bottom: 0;
        font-size: 14px;
        text-align: center;
      }
    }
    .name,
    .rate {
      font-size: 16px;
      font-weight: 400;
      text-transform: capitalize;
      line-height: 20px;
      margin-bottom: 5px;
      color: $white-color;
      text-align: left;
      @include mobile_device {
        text-align: center;
      }
    }
    .rate {
      display: flex;
      margin-bottom: 0;
      @include up_large {
        font-size: 12px;
      }
      @include mobile_device {
        justify-content: center;
        font-size: 12px;
        margin-bottom: 0;
      }
      .span {
        line-height: 23px;
        margin-right: 6px;
      }
      img {
        width: 90px;
        @include up_large {
          width: 80px;
        }
        @include extra_large {
          width: 70px;
        }
        @include mobile_device {
          width: 60px;
        }
      }
    }
  }

  &.reviewIo {
    .right {
      .name {
        text-transform: initial;
      }
    }
    .left {
      .span {
        vertical-align: center
      }
    }
  }
}
